import React, { useEffect } from 'react';
import { BaseWrapper,ToolBar } from '../../Components/Layout/BaseLayouts/Base/Base';
import { ButtonGroup, Button } from '../../Components/Button/Buttons';
import { CreateAtlasWrapper } from '../../Components/Layout/BaseLayouts/Base/Base';
import { TabHandler, TabItem } from '../../Components/Navigation/TabHandler/TabHandler';
import { ContentBase } from '../../Components/Layout/BaseLayouts/BaseContent/ContentBase';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Sections
import { PosHeader } from './ATLAS-MESSAGE-TABS/POSITION/PosHeader';
import { PosBewilligung } from './ATLAS-MESSAGE-TABS/POSITION/PosBewilligung';
import { PosVerpackung } from './ATLAS-MESSAGE-TABS/POSITION/PosVerpackung';
import { PosUnterlage } from './ATLAS-MESSAGE-TABS/POSITION/PosUnterlage';

import { usePositionData } from '../../Utils/Contexts/PositionDataContext';
import { useFormData } from '../../Utils/Contexts/FormDataContext';

import { toast } from 'react-toastify';

//Mesage send
import { CreateAtlasMessage } from '../../Api/SendAtlasMessage/SendAtlasMessage';

function CreateAtlasMessagePosition() {

    const {activePosition, deleteLastPosition, atlasPositionData} = usePositionData();
    const  {
        headerData, 
        bewilligung, 
        suplyActors, 
        befoerderungsMittelAnDerGrenze, 
        zusätzlicheInformationen, 
        unterlageHeader
    } = useFormData();

    console.log(activePosition)

    useEffect(() => {
        const handleBeforeUnlaod = (e) =>{
            const message = "Möchten Sie die Seite wirklich verlassen?";
            e.returnValue = message;
            return message;
        }

        window.addEventListener('beforeunload', handleBeforeUnlaod);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnlaod);
        }
    })

    const [searchParams] = useSearchParams();
    const step = searchParams.get('step');

    let positionData = null;

    switch(step) {
        case 'articleposition':
            positionData = <PosHeader />;
            break; 
        case 'bewilligung':
            positionData = <PosBewilligung />;
            break; 
        case 'verpackung':
            positionData = <PosVerpackung />;
            break; 
        case 'unterlage':
            positionData = <PosUnterlage />;
            break; 
        default: 
            positionData = <PosHeader />;
            break;
    } 


    //Handle Query change
    const navigate = useNavigate();


    const goToStep = (step) => {
        navigate(`?step=${step}`);
    }

    //cancle the atlas request
    const CancleMessageCreation = () => {
        navigate("/app/reybex/listitems");
    }


    //HandleSubmit
    const HandleAtlasMessage = () => {
        CreateAtlasMessage(
            atlasPositionData,
            headerData, 
            bewilligung, 
            suplyActors, 
            befoerderungsMittelAnDerGrenze, 
            zusätzlicheInformationen, 
            unterlageHeader
        );
        
        toast.success("Nachricht and Atlas übermittelt", {
            position: "bottom-right",
        });
    }

    return ( 
        <>
            <BaseWrapper>
                <ToolBar>
                    {/*Here the Toolbar buttons will be displayed*/}
                    <ButtonGroup>
                        <Button onClick={() => navigate(-1)}>Kopfdaten</Button>
                        <Button onClick={() => CancleMessageCreation()}>Abbrechen</Button>
                        <Button onClick={() => deleteLastPosition()}>Letzte Position löschen</Button>
                        <Button onClick={() => HandleAtlasMessage()}>Anmeldung Senden</Button>
                    </ButtonGroup>
                </ToolBar>


                <CreateAtlasWrapper>
                    <TabHandler>
                        <TabItem goTo={() => goToStep("articleposition")} active={step === "articleposition"}>Warenposition</TabItem>
                        <TabItem goTo={() => goToStep("bewilligung")} active={step === "bewilligung"}>Bewilligung</TabItem>
                        <TabItem goTo={() => goToStep("verpackung")} active={step === "verpackung"}>Verpackung</TabItem>
                        <TabItem goTo={() => goToStep("unterlage")} active={step === "unterlage"}>Unterlage</TabItem>
                        <TabItem>Sonstiger Verweis</TabItem>
                    </TabHandler>


                    <ContentBase>
                        {positionData}
                    </ContentBase>

                </CreateAtlasWrapper>
            
            </BaseWrapper>
        </>
     );
}

export default CreateAtlasMessagePosition;