import React, { useEffect, useState } from 'react';

import { AtlasMessageGrid, AtlasMessageGridItem } from '../../../../Components/Layout/Grids/AtlasMessageGrid/AtlasMessageGrid';
import { FormWrapper } from '../../../../Components/Layout/Forms/FormWrapper/FormWrapper';
import { FormBox } from '../../../../Components/Layout/Forms/FormBox/FormBox';
import { Container } from '../../../../Components/Layout/Container/Container';


//input types
import { Input } from '../../../../Components/Input/TextField/Input';
import { DropDownButton, FullWidtDropDown } from '../../../../Components/Input/DropDown/Dropdown';

//importing the context for Data
import { useFormData } from '../../../../Utils/Contexts/FormDataContext';


//Import Tab Header
import { AccordionContainer, AccordionTab, AccordionGrid, AccordionDisplayText } from '../../../../Components/DataDisplay/AccordionTab/Accordion';



function HeaderData() {

    const {headerData, updateHeaderData, atlasData, atlasAddressData} = useFormData();

    //Zeitpunkt der Anmeldung
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("de-DE"); // 29.10.2024
    const formattedTime = currentDate.toLocaleTimeString("de-DE"); // 08:56:57

    const date = `${formattedDate} - ${formattedTime}`;


    const [atlasCodeList, setAtlasCodeList] = useState([]);
    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const handleHeaderChange = (e) => {
        const { name, value } = e.target;
        updateHeaderData({ [name]: value });
    };


    const handleSelectChange = (name, value) => {
        updateHeaderData({ [name]: value });
    };


    //Handle Accordion Open
    const handleAccordionOpen = () => {
        console.log('Accordion Open');
    }

    return ( 
        <>
        <FormWrapper action='submit'>
            <AtlasMessageGrid>
                {/*Left*/}
                <AtlasMessageGridItem>
                    <FormBox header="Allgemeine Informationen">
                        <Input
                        onChange={handleHeaderChange}  
                        value={date} 
                        label='Zeitpunkt der Anmeldung'
                        name='lrn'
                        isDisabled={true}
                        />
                        {/*HERE WE HAVE TO ADD THE DROPDOWN FOR THE COUNTRY*/}
                        <DropDownButton
                            name='Ausfuhrland'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '129')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('ausfuhrland', item)}
                            dropname='ausfuhrland'
                        />
                        <DropDownButton
                            name='Bestimmungsland'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '104')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('bestimmungsLand', item)}
                            dropname='bestimmungsLand'
                        />
                    </FormBox>
                    <FormBox inlineHeader="Anmeldeart">
                        <DropDownButton
                            name='Art der Ausfuhranmeldung'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '133')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('artderAnmeldung', item)}
                            dropname='artderAnmeldung'
                        />
                    </FormBox>

                    <FormBox inlineHeader="Allgemeine Angaben">
                        <Input
                            onChange={handleHeaderChange}
                            value={headerData.kopfDatumdesAusgangs} 
                            label='Datum des Ausgangs'
                            name='kopfDatumdesAusgangs'
                            isDisabled={false}
                            type='date'
                        />
                        <DropDownButton
                            name='Sicherheit'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '108')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('sicherheit', item)}
                            dropname='sicherheit'
                        />
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.massgeblichesDatum} 
                            label='Maßgebliches Datum'
                            name='massgeblichesDatum'
                            isDisabled={false}
                            type='date'
                        />
                        {/*
                        <DropDownButton
                            name='Container-Indikator'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '108')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('sicherheit', item)}
                            dropname='sicherheit'
                        />
                        */}
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.registriernummerextern} 
                            label='Registriernummer (extern)'
                            name='registriernummerextern'
                            isDisabled={false}
                        />
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.lrn} 
                            label='Bezugsnummer (LRN)'
                            name='lrn'
                            isDisabled={false}
                        />
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.referenznummerUCR} 
                            label='Referenznummer der Sendung (UCR)'
                            name='lreferenznummerUCR'
                            isDisabled={false}
                        />
                        <DropDownButton
                            name='Beförderungskosten (Zahlungsart)'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '137')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('beforderungskostenZahlungsart', item)}
                            dropname='beforderungskostenZahlungsart'
                        />
                        <DropDownButton
                            name='Indikator für Besondere Umstände'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '410')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('besondereUmstande', item)}
                            dropname='besondereUmstande'
                        />
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.gesamtRohmasse} 
                            label='Gesamtrohmasse'
                            name='gesamtRohmasse'
                            isDisabled={false}
                        />
                        <DropDownButton
                            name='Beteiligten-Konstellation'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '106')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('beteiligtenKonstellation', item)}
                            dropname='beteiligtenKonstellation'
                        />
                    </FormBox>

                    <FormBox inlineHeader="Angaben zur Gestellung">
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.zeitpunktDerGestellung} 
                            label='Zeitpunkt der Gestellung'
                            name='zeitpunktDerGestellung'
                            isDisabled={false}
                            type='date'
                        />
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.zeitpunktdesEndesderLadetatigkeit} 
                            label='Zeitpunkt des Endes der Ladetätigkeit'
                            name='zeitpunktdesEndesderLadetatigkeit'
                            isDisabled={false}
                            type='date'
                        />
                    </FormBox>

                    <FormBox inlineHeader="Angaben zum Beförderungsmittel">
                        <DropDownButton
                            name='Inländischer Verkehrszweig'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '110')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('inlandischerVerkehrszweig', item)}
                            dropname='inlandischerVerkehrszweig'
                        />
                        <DropDownButton
                            name='Verkehrszweig an der Grenze'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '110')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('verkehrszweigAnDerGrenze', item)}
                            dropname='verkehrszweigAnDerGrenze'
                        />
                        <DropDownButton
                            name='Art der Identifikation des Beförderungsmittels'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '111')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('beforderungsmittelderGrenzeArt', item)}
                            dropname='beforderungsmittelderGrenzeArt'
                        />
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.beforderungsmittelderGrenzeKennzeichen} 
                            label='Kennzeichen des Beförderungsmittels'
                            name='beforderungsmittelderGrenzeKennzeichen'
                            isDisabled={false}
                        />
                        <DropDownButton
                            name='Staatszugehörigkeit des Beförderungsmittels'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '124')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('beforderungsmittelderGrenzeStaatszugehorigkeit', item)}
                            dropname='beforderungsmittelderGrenzeStaatszugehorigkeit'
                        />
                    </FormBox>

                    <FormBox inlineHeader="Angaben zu den Dienststellen">
                        <DropDownButton
                            name='Ausfuhrzollstelle'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '123')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('ausfuhrzollstelleDienststellennummer', item)}
                            dropname='ausfuhrzollstelleDienststellennummer'
                        />
                        <DropDownButton
                            name='Ausfuhrzollstelle für die ergänzende/ersetzende anmeldung'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '151')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('ausfuhrZollstelleFurDieErganzende', item)}
                            dropname='ausfuhrZollstelleFurDieErganzende'
                        />
                        <DropDownButton
                            name='Vorgesehene Ausgangszollstelle'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '122')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('vorgeseheneAusgangszollstelleDienststellennummer', item)}
                            dropname='vorgeseheneAusgangszollstelleDienststellennummer'
                        />                        
                        <DropDownButton
                            name='Tatsächliche Ausgangszollstelle'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '122')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('tatsachlicheAusgangszollstelleDienststellennummer', item)}
                            dropname='tatsachlicheAusgangszollstelleDienststellennummer'
                        />                        
                        <DropDownButton
                        name='Gestellungszollstelle'
                        placeholder='Bitte auswählen...'
                        items={atlasCodeList
                            ? atlasCodeList.find(item => item.type === '152')?.options || []
                            : []
                        }
                        onSelect={(item) => handleSelectChange('gestellungszollstelle', item)}
                        dropname='gestellungszollstelle'
                        />
                    </FormBox>


                    <FormBox inlineHeader="Angaben zu dem Geschäftsvorgang">
                        <DropDownButton
                            name='Art des Geschäfts'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '105')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('artdesGeschafts', item)}
                            dropname='artdesGeschafts'
                        />
                        <Input
                            onChange={handleHeaderChange}  
                            value={headerData.inRechnunggestellterGesamtbetrag} 
                            label='In Rechnung gestellter Gesamtbetrag'
                            name='inRechnunggestellterGesamtbetrag'
                            isDisabled={false}
                        />
                        <DropDownButton
                            name='Art des Geschäfts'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '114')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('rechnungswahrung', item)}
                            dropname='rechnungswahrung'
                        />
                    </FormBox>


                    <FormBox inlineHeader="Angaben zu den Liederbedingungen">
                            <Container>
                                <DropDownButton
                                    name='Incoterm-Code'
                                    placeholder='Bitte auswählen...'
                                    items={atlasCodeList
                                        ? atlasCodeList.find(item => item.type === '113')?.options || []
                                        : []
                                    }
                                    onSelect={(item) => handleSelectChange('lieferbedingungIncotermCode', item)}
                                    dropname='lieferbedingungIncotermCode'
                                />
                                <DropDownButton
                                    name='UN/Locode'
                                    placeholder='Bitte auswählen...'
                                    items={atlasCodeList
                                        ? atlasCodeList.find(item => item.type === '153')?.options || []
                                        : []
                                    }
                                    onSelect={(item) => handleSelectChange('lieferbedingungUnLocode', item)}
                                    dropname='lieferbedingungUnLocode'
                                />
                                <DropDownButton
                                    name='Ort'
                                    placeholder='Bitte auswählen...'
                                    items={atlasCodeList
                                        ? atlasCodeList.find(item => item.type === '0')?.options || []
                                        : []
                                    }
                                    onSelect={(item) => handleSelectChange('lieferbedingungOrt', item)}
                                    dropname='lieferbedingungOrt'
                                />
                                <DropDownButton
                                    name='Land'
                                    placeholder='Bitte auswählen...'
                                    items={atlasCodeList
                                        ? atlasCodeList.find(item => item.type === '128')?.options || []
                                        : []
                                    }
                                    onSelect={(item) => handleSelectChange('lieferbedingungLand', item)}
                                    dropname='lieferbedingungLand'
                                />
                                <Input
                                    onChange={handleHeaderChange}  
                                    value={headerData.lieferbedingungText} 
                                    label='Text'
                                    name='lieferbedingungText'
                                    isDisabled={false}
                                />
                            </Container>
                    </FormBox>

                </AtlasMessageGridItem>


                {/*Right*/}
                <AtlasMessageGridItem>
                    <AccordionContainer header="Beteiligtenkonstelation">
                        <AccordionTab tabName='Empfänger' onOpen={() => handleAccordionOpen}>
                            <FullWidtDropDown
                                name='Empfänger wählen'
                                placeholder='Bitte auswählen...'
                                items={atlasAddressData
                                    ? atlasAddressData.find(item => item.type === '02')?.addresses || []
                                    : []
                                }
                                dropname='empfänger'
                            />
                            <Container size='largeCenter'>
                                <AccordionDisplayText labelText='Name'>
                                    <span>
                                        {headerData.empfanger.name}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Straße u. Hausnummer'>
                                    <span>
                                        {headerData.empfanger.strasse}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Postleitzahl'>
                                    <span>
                                        {headerData.empfanger.plz}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Ort'>
                                    <span>
                                        {headerData.empfanger.ort}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Land'>
                                    <span>
                                        {headerData.empfanger.land}
                                    </span>
                                </AccordionDisplayText>
                            </Container>
                        </AccordionTab>
                        <AccordionTab tabName='Anmelder'>
                            <FullWidtDropDown
                                name='Anmelder wählen'
                                placeholder='Bitte auswählen...'
                                items={atlasAddressData
                                    ? atlasAddressData.find(item => item.type === '01')?.addresses || []
                                    : []
                                }
                                dropname='anmelder'
                            />
                            <AccordionGrid>
                                <Container size='largeCenter'>
                                    <AccordionDisplayText labelText='Niederlassungsnummer'>
                                        <span>
                                            {headerData.anmelder.niederlassungsNummer}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Name'>
                                        <span>
                                            {headerData.anmelder.name}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Straße u. Hausnummer'>
                                        <span>
                                            {headerData.anmelder.strasse}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Postleitzahl'>
                                        <span>
                                            {headerData.anmelder.plz}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Ort'>
                                        <span>
                                            {headerData.anmelder.ort}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Land'>
                                        <span>
                                            {headerData.anmelder.land}
                                        </span>
                                    </AccordionDisplayText>
                                </Container>
                                <Container size='largeCenter'>
                                    <AccordionDisplayText labelText='TIN EU'>
                                        <span>
                                            {headerData.anmelder.tin}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Name'>
                                        <span>
                                            {headerData.anmelder.ansprechName}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='E-Mail-Adresse'>
                                        <span>
                                            {headerData.anmelder.ansprechEmail}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Telefonnummer'>
                                        <span>
                                            {headerData.anmelder.phone}
                                        </span>
                                    </AccordionDisplayText>
                                </Container>
                            </AccordionGrid>
                        </AccordionTab>

                        <AccordionTab tabName='Vertreter'>
                            <FullWidtDropDown
                                name='Anmelder wählen'
                                placeholder='Bitte auswählen...'
                                items={atlasAddressData
                                    ? atlasAddressData.find(item => item.type === '04')?.addresses || []
                                    : []
                                }
                                dropname='vertreter'
                            />
                            <AccordionGrid>
                                <Container size='largeCenter'>
                                    <AccordionDisplayText labelText='Niederlassungsnummer'>
                                        <span>
                                            {headerData.vertreter.niederlassungsNummer}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Name'>
                                        <span>
                                            {headerData.vertreter.ansprechName}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='E-Mail-Adresse'>
                                        <span>
                                            {headerData.vertreter.ansprechEmail}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Telefonnummer'>
                                        <span>
                                            {headerData.vertreter.phone}
                                        </span>
                                    </AccordionDisplayText>
                                </Container>
                                <Container size='largeCenter'>
                                    <AccordionDisplayText labelText='TIN EU'>
                                        <span>
                                            {headerData.anmelder.tin}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Name'>
                                        <span>
                                            {headerData.anmelder.ansprechName}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='E-Mail-Adresse'>
                                        <span>
                                            {headerData.anmelder.ansprechEmail}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Telefonnummer'>
                                        <span>
                                            {headerData.anmelder.phone}
                                        </span>
                                    </AccordionDisplayText>
                                </Container>
                            </AccordionGrid>
                        </AccordionTab>


                        <AccordionTab tabName='Subunternehmer'>
                            <FullWidtDropDown
                                name='Anmelder wählen'
                                placeholder='Bitte auswählen...'
                                items={atlasAddressData
                                    ? atlasAddressData.find(item => item.type === '04')?.addresses || []
                                    : []
                                }
                                dropname='subunternehmer'
                            />
                            <Container size='largeCenter'>
                                <AccordionDisplayText labelText='Identifikationsnummer'>
                                    <span>
                                        {headerData.subunternehmer.tin}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Niederlassungsnummer'>
                                    <span>
                                        {headerData.subunternehmer.niederlassungsNummer}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Name'>
                                    <span>
                                        {headerData.subunternehmer.name}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Straße u. Hausnummer'>
                                    <span>
                                        {headerData.subunternehmer.strasse}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Postleitzahl'>
                                    <span>
                                        {headerData.subunternehmer.plz}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Ort'>
                                    <span>
                                        {headerData.subunternehmer.ort}
                                    </span>
                                </AccordionDisplayText>
                                <AccordionDisplayText labelText='Land'>
                                    <span>
                                        {headerData.subunternehmer.land}
                                    </span>
                                </AccordionDisplayText>
                            </Container>
                        </AccordionTab>
                    </AccordionContainer>


                    {/*Angaben zum Warenort*/}
                    <FormBox inlineHeader="Angaben zum Warenort">
                        <Container size='largeCenter'>
                            <DropDownButton
                                name='Art des Ortes'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '142')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('warenortArtdesOrtes', item)}
                                dropname='warenortArtdesOrtes'
                            />
                            <DropDownButton
                                name='Art der Ortsbestimmung'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '141')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('warenortArtDerOrtsbestimmung', item)}
                                dropname='warenortArtDerOrtsbestimmung'
                            />
                            <Input
                                onChange={handleHeaderChange}  
                                value={headerData.warenortBewilligungsnummer} 
                                label='Bewilligungsnummer'
                                name='warenortBewilligungsnummer'
                                isDisabled={true}
                            />
                            <DropDownButton
                                name='Zusätzliche Kennung'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '141')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('warenortZusatzlicheKennung', item)}
                                dropname='warenortZusatzlicheKennung'
                            />
                            <DropDownButton
                                name='UN/Locode'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '153')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('warenortUnLocode', item)}
                                dropname='warenortUnLocode'
                            />
                            <Input
                                onChange={handleHeaderChange}  
                                value={headerData.warenortGnssBreite} 
                                label='GNSS Breite'
                                name='warenortGnssBreite'
                                isDisabled={true}
                            />
                            <Input
                                onChange={handleHeaderChange}  
                                value={headerData.warenortGnssLang} 
                                label='GNSS Länge'
                                name='warenortBewilligungsnummer'
                                isDisabled={true}
                            />
                        </Container>
                    </FormBox>
                </AtlasMessageGridItem>
            </AtlasMessageGrid>
        </FormWrapper>
        </>
     );
}

export default HeaderData;