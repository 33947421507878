import { useEffect, useState } from 'react';
import './SearchBar.css';
import { CiSearch } from "react-icons/ci";
import { useFormData } from '../../../Utils/Contexts/FormDataContext';


export const SearchBar = ({items}) => {

    const [searchText, setSearchText] = useState('');

    const {setFilteredLs} = useFormData();


    useEffect(() => {
        if(items) {
            const filteredItems = items.filter(item => item.docNumber.includes(searchText) || item.customer.name.includes(searchText) || 
            item.customer.customerNo.includes(searchText));
            console.log(filteredItems);
            setFilteredLs(filteredItems)
        }   
    }, [items, searchText, setFilteredLs])

    return(
        <>
        <div className="bap_search_bar_wrapper">
            <div className="bap_search_icon_wrapper">
                <CiSearch />
            </div>
            <input 
                type="text" 
                onChange={(e) => setSearchText(e.target.value)}
            />
        </div>
        </>
    )
}