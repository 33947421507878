import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { Container } from "../../../../Components/Layout/Container/Container"
import { Input } from "../../../../Components/Input/TextField/Input"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"


//Table
import { TableContainer } from "../../../../Components/DataDisplay/Table/Table"
import { useEffect, useState } from "react"

export const SuplyChaingActors = () => {

    const {suplyActors, addSuplyActorEntry, atlasData} = useFormData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);


    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const [suplyActor, setNewSuplyActor] = useState({
        "funktion": "", 
        "identifikationsnummer": "",
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSuplyActor((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewSuplyActor((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddBewilligung = (e) =>{
        e.preventDefault();
        addSuplyActorEntry(suplyActor);
        setNewSuplyActor({ funktion: '', identifikationsnummer: '' });
    }

    console.log(suplyActors);

    return(
        <>
        <FormWrapper action="submit">
            <ColumnGrid>
                <FormBox header="Bewilligung" type="center">
                    <Container size="small">
                        <Input
                            label="Sequenznummer"
                            isDisabled={true}
                            onChange={handleInputChange}
                            name="anzahlEintrage"
                        />
                        <DropDownButton
                            name='Funktion'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '146')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('funktion', item)}
                            dropname='funktion'
                        />
                        <Input
                            label="Identifikationsnummer"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="identifikationsnummer"
                            value={suplyActor.identifikationsnummer}
                        />

                        <ButtonGroup>
                            <Button type="submit" onClick={handleAddBewilligung}>
                                Hinzufügen
                            </Button>
                        </ButtonGroup>

                    </Container>
                </FormBox>

                <TableContainer>
                    {suplyActors.lieferkettenBeteiligter.length > 0 ? (
                        suplyActors.lieferkettenBeteiligter.map((item, index) => (
                            <div key={index}>
                                <span>{item.anzahlEintrage}</span>
                                <span>{item.funktion}</span>
                                <span>{item.identifikationsnummer}</span>
                            </div>
                        ))
                    ) : (
                        <span>Keine Daten vorhanden</span>
                    )}
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}