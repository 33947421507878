import React, { useEffect, useRef, useState } from 'react';
import './Dropdown.css';
import { IoIosArrowDown } from 'react-icons/io';
import { IoAddOutline } from "react-icons/io5";

//Outside Click handler
import { useOutsideClick } from '../../../Utils/CloseHandler/useOutsideClick';
import { getHighestZIndex } from '../../../Utils/Objectfinder/HighestZIndex';
import { useFormData } from '../../../Utils/Contexts/FormDataContext';
import { usePositionData } from '../../../Utils/Contexts/PositionDataContext';


// Dropdown Button Component
export const DropDownButton = ({ name = "select name", dropname ="", placeholder = "default select", items = [], onSelect}) => {

    //Get Current FormData: 
    const {headerData} = useFormData();
    const {activePosition} = usePositionData();

    //Handle Z-Index
    const [zIndex, setZIndex] = useState(1);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(placeholder);
    const [isDefaultSet, setIsDefaultSet] = useState(false);

    const toggleDropdown = () => {
        if(!isOpen) {
            const highestZIndex = getHighestZIndex();

            setZIndex(highestZIndex + 1);
        }
        setIsOpen(!isOpen);
    };


    // Set default if isSelected is true or headerData has data (nur beim ersten Mal)
    // Check for Position and set defaults
    useEffect(() => {

            // Zugriff auf das Feld mit dropname
            const fieldValue = activePosition[dropname];

            const CodeLists = items;
            if (!fieldValue || fieldValue === "") {
                if (!isDefaultSet) {
                    const receivedItems = items;
                    
                    // Prüfen, ob der aktuelle `dropname` in `headerData` existiert und einen nicht-leeren Wert hat
                    if (dropname && headerData[dropname] !== undefined && headerData[dropname] !== '') {
                        const foundItem = receivedItems.find(item => item.code === headerData[dropname]);
                        
                        if (foundItem) {
                            // Setze das gefundene Item als ausgewählt
                            setSelectedItem(`${foundItem.code} - ${foundItem.description}`);
                            setIsDefaultSet(true);
                            return; 
                        }
                    }

                    const defaultItem = receivedItems.find(item => item.isSelected === true);
                    if (defaultItem) {
                        setSelectedItem(`${defaultItem.code} - ${defaultItem.description}`);
                        setIsDefaultSet(true); 
                    }
                }
            } else {

                const foundItem = CodeLists.find(item => item.code === fieldValue);
                console.log("This is the found Item:", foundItem);
                
                if (foundItem) {
                    setSelectedItem(`${foundItem.code} - ${foundItem.description}`);
                }
            }
        
    }, [items, headerData, dropname, isDefaultSet, activePosition]);

    const handleSelect = (description, code) => {
        setSelectedItem(`${code} - ${description}`); 
        setIsOpen(false);
        onSelect(code);
        setSearch('');
    };

    const HandelSetDefault = (name) => {
        setSelectedItem(name);
        setIsOpen(false);
    }

    //Handle Search in items

    const [search, setSearch] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        if (search.length > 0) {
            const newFilteredItems = items.filter(item => item.description.toLowerCase().includes(search.toLowerCase()));
            setFilteredItems(newFilteredItems);
        } else {
            setFilteredItems(items);
        }
    }, [items, search]);


    //Handle outside click
    const dropdownref = useRef();


    const handleOutsideClick = () => {
        setIsOpen(false);
        setSearch('');
    }

    useOutsideClick(dropdownref, handleOutsideClick)


    //Handle Default Back
    useEffect(() => {
        if (selectedItem === '') {
            setSelectedItem(placeholder);
        }
    }, [selectedItem, placeholder]);


  return (
    <>
    <div className="bap_dropdown_wrapper_main" ref={dropdownref}>
        <div className="bap_dropdown_label_wrapper">
            <label className='pn_dropdown_label'>{name}</label>
        </div>
        <div className="pn_dropdown_container_classic" style={{ zIndex: zIndex }}>
            <div className="bap_drop_button_wrapper">
                <div className="pn_dropdown_btn_select" onClick={toggleDropdown}>
                    <span>{selectedItem}</span>
                    <IoIosArrowDown className='pn_dropdown_icon' />
                </div>

                <div className="bap_add_new_code_item">
                    <IoAddOutline  className='bap_add_item'/>
                </div>
            </div>

            {isOpen && (
            <div className="pn_dropdown_result_wrapper">
                <DropDownSearch 
                onChange={handleSearch}
                value={search}
                />
                
                <DropDownItem onClick={() => HandelSetDefault("Bitte auswählen...")}>
                    Bitte auswählen...
                </DropDownItem> 


                {filteredItems.map((item, index) => (
                <DropDownItem key={index} onClick={() => handleSelect(item.description, item.code)}>
                    {item.code} - {item.description}
                </DropDownItem>
                ))}
            </div>
            )}
        </div>
    </div>
    </>
  );
};

// Dropdown Item Component
export const DropDownItem = ({ children, onClick }) => {
  return (
    <>
        <div className="pn_dropdown_item" onClick={onClick}>
            <span>{children}</span>
        </div>
    </>
  );
};

export const DropDownSearch = ({onChange, value}) => {

    return (
        <>
        <div className="bap_search_bar_item_inside_dropdown">
            <input 
            type="text" 
            placeholder='suchen...'
            onChange={onChange}
            value={value}
            />
        </div>
        </>
    );
}




/*Full With DropDown*/

export const FullWidtDropDown = ({ dropname ="", placeholder = "default select", items = [], noEdit, onSelect}) => {

    //Get Header information
    const {headerData, setHeaderData} = useFormData();

    //Handle Z-Index
    const [zIndex, setZIndex] = useState(1);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(placeholder);

    const toggleDropdown = () => {
        if(!isOpen) {
            const highestZIndex = getHighestZIndex();

            setZIndex(highestZIndex + 1);
        }
        setIsOpen(!isOpen);
    };

    const handleSelect = (item) => {

        if(onSelect){
            onSelect(item)
            setSelectedItem(`${item.value} - ${item.label}`)
            setIsOpen(false);
        }

        if(dropname === 'empfänger'){
            setSelectedItem(`${item.name} - ${item.stasse}, ${item.plz}, ${item.ort}, ${item.land}`);
            // Erstellen einer neuen Kopie von headerData und überschreiben der entsprechenden Felder
            setHeaderData({
                ...headerData, // Beibehaltung der bestehenden Daten
                empfanger: {
                    ...headerData.empfanger, // Beibehaltung der bestehenden "empfanger" Daten
                    name: item.name || "",
                    strasse: item.strasse || "",
                    niederlassungsNummer: item.niederlassungsNummer || "",
                    plz: item.plz || "",
                    tin: item.tin || "",
                    ort: item.ort || "",
                    land: item.land || ""
                }
            });
    
            setIsOpen(false);
            setSearch('');

        } else if (dropname === 'anmelder'){
            // Erstellen einer neuen Kopie von headerData und überschreiben der entsprechenden Felder
            setSelectedItem(`${item.name} ${item.ansprechName} - ${item.stasse}, ${item.plz}, ${item.ort}, ${item.land}`);
            setHeaderData({
                ...headerData,
                anmelder: {
                    ...headerData.anmelder,
                    tin: item.tin || "",
                    niederlassungsNummer: item.niederlassungsNummer || "",
                    name: item.name || "",
                    strasse: item.strasse || "",
                    plz: item.plz || "",
                    ort: item.ort || "",
                    land: item.land || "",
                    phone: item.phone || "",
                    ansprechName: item.ansprechName || "",
                    ansprechEmail: item.ansprechEmail || ""
                },
            });
    
            setIsOpen(false);
            setSearch('');
        } else if (dropname === "vertreter"){
            // Erstellen einer neuen Kopie von headerData und überschreiben der entsprechenden Felder
            setHeaderData({
                ...headerData,
                vertreter: {
                    tin: item.tin || "",
                    niederlassungsNummer: item.niederlassungsNummer || "",
                    phone: item.phone || "",
                    ansprechName: item.ansprechName || "",
                    ansprechEmail: item.ansprechEmail || ""
                },
            });
    
            setIsOpen(false);
            setSearch('');
        }else if(dropname === "subunternehmer"){
            // Erstellen einer neuen Kopie von headerData und überschreiben der entsprechenden Felder
            setSelectedItem(`${item.name} ${item.ansprechName} - ${item.stasse}, ${item.plz}, ${item.ort}, ${item.land}`);
            setHeaderData({
                ...headerData,
                subunternehmer: {
                    tin: item.tin || "",
                    niederlassungsNummer: item.niederlassungsNummer || "",
                    name: item.name || "",
                    strasse: item.strasse || "",
                    plz: item.plz || "",
                    ort: item.ort || "",
                    land: item.land || "",
                },
            });
    
            setIsOpen(false);
            setSearch('');
        }
    };

    const HandelSetDefault = (name) => {
        setSelectedItem(name);
        setIsOpen(false);
    }

    //Handle Search in items

    const [search, setSearch] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        if (search.length > 0) {
            const newFilteredItems = items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
            setFilteredItems(newFilteredItems);
        } else {
            setFilteredItems(items);
        }
    }, [items, search]);


    //Handle outside click
    const dropdownref = useRef();


    const handleOutsideClick = () => {
        setIsOpen(false);
        setSearch('');
    }

    useOutsideClick(dropdownref, handleOutsideClick)


    //Handle Default Back
    useEffect(() => {
        if (selectedItem === '') {
            setSelectedItem(placeholder);
        }
    }, [selectedItem, placeholder]);


    return(
        <>
        <div className="bap_main_calssic_full_widt_wrapper" style={{ zIndex: zIndex }} ref={dropdownref}>
            <div className="bap_drop_button_wrapper">
                <div className="pn_dropdown_btn_select" onClick={toggleDropdown}>
                    <span>{selectedItem}</span>
                    <IoIosArrowDown className='pn_dropdown_icon' />
                </div>

                {noEdit ? (
                    <>
                    <div className="bap_add_new_code_item">
                        <IoAddOutline  className='bap_add_item'/>
                    </div>
                    </>
                ) : null}
            </div>

            {isOpen && (
            <div className="pn_dropdown_result_wrapper">
                <DropDownSearch 
                onChange={handleSearch}
                value={search}
                />
                
                <DropDownItem onClick={() => HandelSetDefault("Bitte auswählen...")}>
                    Bitte auswählen...
                </DropDownItem> 


                {filteredItems.map((item, index) => (
                <DropDownItem key={index} onClick={() => handleSelect(item)}>
                    {dropname === "position" ? (
                        <>
                        {item.value} - {item.label}
                        </>
                    ) : (
                        <>
                        {item.name}{item.ansprechName}, {item.tin}, {item.referenceNum}
                        </>
                    )}
                </DropDownItem>
                ))}
            </div>
            )}
        </div>
        </>
    )

}