import { AtlasMessageGrid, AtlasMessageGridItem } from "../../../../Components/Layout/Grids/AtlasMessageGrid/AtlasMessageGrid";
import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper";
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox";
import { Input } from "../../../../Components/Input/TextField/Input";
import { DropDownButton, FullWidtDropDown } from "../../../../Components/Input/DropDown/Dropdown";
import { useFormData } from "../../../../Utils/Contexts/FormDataContext";
import { usePositionData } from "../../../../Utils/Contexts/PositionDataContext";
import { useEffect, useState } from "react";
import { AccordionContainer, AccordionTab, AccordionDisplayText } from "../../../../Components/DataDisplay/AccordionTab/Accordion";
import { Container } from "../../../../Components/Layout/Container/Container";

export const PosHeader = () => {

    const { atlasData, headerData } = useFormData();
    const { updateSpecificPosition, atlasPositionData, setActivePosition, activePosition } = usePositionData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);

    useEffect(() => {
        if (atlasData) {
            setAtlasCodeList(atlasData);
        }
    }, [atlasData]);

    const [positionNumber, setPositionNumber] = useState(null);

    useEffect(() => {
        if(positionNumber){
            const position = atlasPositionData.find((PosItem) => PosItem.warePositionsnummer === positionNumber)
            setActivePosition(position)
        }else{
            setPositionNumber(1)
        }
    }, [positionNumber, atlasPositionData, setActivePosition]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const position = JSON.parse(localStorage.getItem("position_number"));

        if (position) {
            updateSpecificPosition(position, { [name]: value });
        }
    };

    console.log(positionNumber)

    const handleSelectChange = (name, value) => {
        const position = JSON.parse(localStorage.getItem("position_number"));

        if(position){
            updateSpecificPosition(position, { [name]: value });
        }
    };


    //Position change
    const handlePositionChange = (warePositionsnummer) => {
        setPositionNumber(warePositionsnummer);
        localStorage.setItem("position_number", warePositionsnummer);

        // Aktualisiere die Position basierend auf der neuen Auswahl
        const newPosition = atlasPositionData.find(item => item.warePositionsnummer === warePositionsnummer);
        if (newPosition) {
            setActivePosition(newPosition);
        }
    };

    return(
        <>
        <FormWrapper>
            <AtlasMessageGrid>
                {activePosition ? (
                    <>
                    <AtlasMessageGridItem>
                            <FullWidtDropDown
                                name='Empfänger wählen'
                                placeholder='Bitte auswählen...'
                                items={atlasPositionData.map(item => ({
                                    value: item.warePositionsnummer, // Setze die Positionsnummer als Wert
                                    label: item.wareWarenbezeichnung // Setze die Beschreibung für das Dropdown
                                }))}
                                dropname='position'
                                onSelect={(item) => handlePositionChange(item.value)} // Aktualisiere mit dem Wert der ausgewählten Position
                            />       
                        <FormBox header="Allgemeine Informationen für die Ausfuhranmeldung">
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.warePositionsnummer} 
                                label='Positionsnummer'
                                name='warePositionsnummer'
                                isDisabled={true}
                            />

                            <Input
                                onChange={handleInputChange}
                                value={activePosition.wareEigenmasse} 
                                label='Eigenmasse (in Kg)'
                                name='wareEigenmasse'
                                isDisabled={false}
                            />
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.wareRohmasse} 
                                label='Rohmasse (in Kg)'
                                name='wareRohmasse'
                                isDisabled={false}
                            />
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.mengeinbesondererMabeinheit} 
                                label='Menge in besonderer Maßeinheit'
                                name='mengeinbesondererMabeinheit'
                                isDisabled={false}
                            />
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.statistischerWert} 
                                label='Statistischer Wert'
                                name='statistischerWert'
                                isDisabled={false}
                            />
                            <DropDownButton
                                name='Urpsrungsland'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '128')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('ursprungsland', item)}
                                dropname='ursprungsland'
                            />
                            <DropDownButton
                                name='Ursprung Versenderregion'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '118')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('ursprungsVersendungsregion', item)}
                                dropname='ursprungsVersendungsregion'
                            />
                            <DropDownButton
                                name='Beförderungskosten (Zahlungsart)'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '137')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('beforderungskostenZahlungsart', item)}
                                dropname='beforderungskostenZahlungsart'
                            />
                        </FormBox>
                    </AtlasMessageGridItem>
                    <AtlasMessageGridItem>                
                        <FormBox header="Beteiligtenkonstelation" inlineHeader="Verfahren">
                            <DropDownButton
                                name='Beantragtes Verfahren'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '115')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('beantragtesVerfahren', item)}
                                dropname='beantragtesVerfahren'
                            />
                            <DropDownButton
                                name='Vorhergehendes Verfahren'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '116')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('vorhergehendesVerfahren', item)}
                                dropname='vorhergehendesVerfahren'
                            />
                            <DropDownButton
                                name='Zusätzliches Verfahren'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '136')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('zusatzlichesVerfahren', item)}
                                dropname='zusatzlichesVerfahren'
                            />
                        </FormBox>

                        <FormBox inlineHeader="Geschäftsvorgang">
                            <DropDownButton
                                name='Art des Geschäfts'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '105')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('artdesGeschafts', item)}
                                dropname='artdesGeschafts'
                            />
                        </FormBox>

                        {/* 
                        <FormBox inlineHeader="Passive Veredelung">
                            <DropDownButton
                                name='Art des Geschäfts'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '115')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('artdesGeschafts', item)}
                                dropname='artdesGeschafts'
                            />
                        </FormBox>
                        */}
                        <AccordionContainer>
                            <AccordionTab tabName='Empfänger'>
                                <Container size='largeCenter'>
                                    <AccordionDisplayText labelText='Name'>
                                        <span>
                                            {headerData.empfanger.name}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Straße u. Hausnummer'>
                                        <span>
                                            {headerData.empfanger.strasse}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Postleitzahl'>
                                        <span>
                                            {headerData.empfanger.plz}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Ort'>
                                        <span>
                                            {headerData.empfanger.ort}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Land'>
                                        <span>
                                            {headerData.empfanger.land}
                                        </span>
                                    </AccordionDisplayText>
                                </Container>
                            </AccordionTab>


                            <AccordionTab tabName="Versender">
                                <span>In Arbeit</span>
                            </AccordionTab>
                        </AccordionContainer>
                    </AtlasMessageGridItem>
                    </>
                ) : "Nicht vorhanden..."}
            </AtlasMessageGrid>
        </FormWrapper>
        </>
    )
};