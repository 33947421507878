import React, { useState } from 'react';

import base from '../../../Api/Base.json';

//import wrapper
import { SetUpCard } from '../../../Components/Layout/SetUpCard/SetUpCard';
import { FormWrapper } from '../../../Components/Layout/Forms/FormWrapper/FormWrapper';
import { InlineInput } from '../../../Components/Input/TextField/Input';
import { Button } from '../../../Components/Button/Buttons';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function ReyBexSetup() {

  //Handle Navigation
  const navigate = useNavigate();
    //Handle Form collect
    const [ReyBexAuth, setReyBexAuth] = useState({
        Username: '',
        Password: ''
    });


  // Funktion zum Verarbeiten der Eingaben (onChange-Handler)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReyBexAuth({
      ...ReyBexAuth, 
      [name]: value 
    });
  };


  //Handle Submit

  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault(); 
    setLoading(true); 
    try {
      const res = await axios.post(`${base.reybex}login`, {
        username: ReyBexAuth.Username, 
        password: ReyBexAuth.Password,
      });
  
      console.log('Response:', res.data);  // Verarbeitung der API-Antwort

      if(res.status === 200){
        localStorage.setItem('r_token', res.data.token);
        toast.success("Anmeldung erfolgreich", {
          position: "bottom-right",
        })
        if(localStorage.getItem('r_token')){
          navigate('/app/setup?step=atlas');
        }
      }

    } catch (error) {
      console.error('Error during login:', error.response ? error.response.data : error.message);
    }
    setLoading(false);
  };


  //JUST FOR TESTING
  console.log('ReyBexAuth:', loading);


  console.log(ReyBexAuth);
    return ( 
        <>
        <SetUpCard header="ReyBex">
            <FormWrapper action='submit'>
                <div className="bap_form_wrapper_setup">
                    <InlineInput 
                    label='Benutzername'
                    value={ReyBexAuth.Username}
                    onChange={handleInputChange}
                    name='Username'
                    />
                    <InlineInput
                    label='Passwort'
                    type='password'
                    value={ReyBexAuth.Password}
                    onChange={handleInputChange}
                    name='Password'
                    />
                    <Button type='submit'onClick={handleLogin} isLoading={loading === true ? true : false}>Anmelden</Button>
                </div>
            </FormWrapper>
        </SetUpCard>
        
        </>
     );
}

export default ReyBexSetup;