import axios from 'axios';
import React, {createContext, useState, useContext, useEffect } from 'react';
import Base from '../../Api/Base.json';
import { useFormData } from './FormDataContext';
import { toast } from 'react-toastify';

import { PageLoader } from '../../Components/LoadingAnimation/PageLoader/PageLoader';

const PositionDataContext = createContext();

export const PositionDataProvider = ({children}) => {

    const { ls_number } = useFormData();
 
    // Data for Reybex and Atlas positions
    const [reybexPositionData, setReybexPositionData] = useState([]);
    const [atlasPositionData, setAtlasPositionData] = useState([]); // Initialisiert als Array

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Get the Reybex data
        const GetReyBexHeaderData = async () => {
            const ID = ls_number.ls_id;

            setLoading(true);
            const r_token = localStorage.getItem('r_token');
            try {
                const reybex = await axios.get(`${Base.reybex}domains/salesItem?salesHead.id=${ID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${r_token}`,
                    },
                });

                if (reybex.status === 200) {
                    const reybexPositionData = await reybex.data;
                    if (reybexPositionData) {
                        setReybexPositionData(reybexPositionData);
                    }
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };

        GetReyBexHeaderData();
    }, [ls_number.ls_id]);


    const [PosBewilligung, setPosBewilligung] = useState([{bewilligung: []}])
    const [PosUnterlage, setPosUnterlage] = useState([{unterlage: []}])
    const [PosVerpackung, setPosVerpackung] = useState([{verpackung: []}])



    //Data Mapping ATLAS HEADER und ReyBex-Daten auf die Positionen
    useEffect(() => {
        if (reybexPositionData) {
            setAtlasPositionData(() => [
                ...reybexPositionData.map((reyPos, index) => ({
                    warePositionsnummer: index + 1 || "",
                    statistischerWert: "",
                    artdesGeschafts: "",
                    ausfuhrLand: "",
                    referenznummerUCR: "",
                    registriernummerextern: "",
                    beantragtesVerfahren: "",
                    vorhergehendesVerfahren: "",
                    zusatzlichesVerfahren: "",
                    ursprungsland: "",
                    ursprungsVersendungsregion: "",
                    wareWarenbezeichnung: reyPos.material.name || "",
                    warecusnummer: "",
                    wareWarennummerKN8: "",
                    wareRohmasse: "",
                    wareEigenmasse: reyPos.netWeight || "",
                    mengeinbesondererMabeinheit: "",
                    beforderungskostenZahlungsart: "",
                    standardaustauschErsatzwarenverkehr: "",
                    datumderWiedereinfuhr: "",
                    zolllagerLrn: "",
                    zolllagerBewilligungArt: "",
                    zolllagerBewilligungReferenznummer: "",
                    vereinfachtErteilteBewilligung: "",
                    bewilligungArt: "",
                    bewilligungReferenznummer: "",
                    uberwachungsZollstelleReferenznummer: "",
                    // Jede Position erhält eigene `bewilligung`-Daten
                    bewilligung: PosBewilligung[index]?.bewilligung.map((item) => ({
                        art: item.art || "",
                        referenznummer: item.referenznummer || "", 
                        bewilligungsinhaber: item.bewilligungsinhaber || "",
                    })) || [],
                    //Verpackung
                    verpackung: PosVerpackung[index]?.verpackung.map((item) => ({
                        artderVerpackung: item.artderVerpackung || "",
                        anzahlderPackstucke: item.anzahlderPackstucke || "",
                        versandzeichen: item.versandzeichen || "",
                        packstuckverweisPositionsnummer: item.packstuckverweisPositionsnummer || "",
                    })) || [],
                    unterlage: PosUnterlage[index]?.unterlage.map((item) => ({
                        art: item.art || "",
                        qualifikator: item.qualifikator || "",
                        referenznummer: item.referenznummer || "",
                        zeilenPositionsnummer: item.zeilenPositionsnummer || "",
                        zusatzlicheAngaben: item.zusatzlicheAngaben || "",
                        detail: item.detail || "",
                        namederausstellendenBehorde: item.namederausstellendenBehorde || "",
                        datumderAusstellung: item.datumderAusstellung || "",
                        gultigkeitsdatum: item.gultigkeitsdatum || "",
                        mabeinheit: item.mabeinheit || "",
                        erganzendeMabeinheit: item.erganzendeMabeinheit || "",
                        menge: item.menge || "",
                        wahrung: item.wahrung || "",
                        betrag: item.betrag || "",
                    })) || [],
                }))
            ]);
        } else {
            console.log("ERROR");
        }
    }, [reybexPositionData, PosBewilligung, PosVerpackung, PosUnterlage]);

// Funktion um eine neue Bewilligung zu einer Position hinzuzufügen
    const addBewilligunPos = (warePositionsnummer, newBewilligungData) => {
        setPosBewilligung((prevBewilligungData) => {
            const updatedBewilligung = [...prevBewilligungData]; // Kopiere die vorherige Liste der Bewilligungen
            const posIndex = warePositionsnummer - 1; // Positionsnummer als Index

            // Wenn noch keine Bewilligung für diese Position existiert, initialisiere sie
            if (!updatedBewilligung[posIndex]) {
                updatedBewilligung[posIndex] = { bewilligung: [] };
            }

            // Füge die neue Bewilligung zur entsprechenden Position hinzu
            updatedBewilligung[posIndex].bewilligung = [
                ...updatedBewilligung[posIndex].bewilligung,
                newBewilligungData
            ];
            return updatedBewilligung;
        });
    };



    // Funktion um eine neue Bewilligung zu einer Position hinzuzufügen
    const addVerpackungPos = (warePositionsnummer, newVerpackungData) => {
        setPosVerpackung((prevVerapckungData) => {
            const updatedVerpackungData = [...prevVerapckungData]; // Kopiere die vorherige Liste der Bewilligungen
            const posIndex = warePositionsnummer - 1; // Positionsnummer als Index

            // Wenn noch keine Bewilligung für diese Position existiert, initialisiere sie
            if (!updatedVerpackungData[posIndex]) {
                updatedVerpackungData[posIndex] = { verpackung: [] };
            }

            // Füge die neue Bewilligung zur entsprechenden Position hinzu
            updatedVerpackungData[posIndex].verpackung = [
                ...updatedVerpackungData[posIndex].verpackung,
                newVerpackungData
            ];
            return updatedVerpackungData;
        });
    };


    const addUnterlagePos = (warePositionsnummer, newUntrlageData) => {
        setPosUnterlage((prevUnterlageData) => {
            const updatedUnterlageData = [...prevUnterlageData]; // Kopiere die vorherige Liste der Bewilligungen
            const posIndex = warePositionsnummer - 1; // Positionsnummer als Index

            // Wenn noch keine Bewilligung für diese Position existiert, initialisiere sie
            if (!updatedUnterlageData[posIndex]) {
                updatedUnterlageData[posIndex] = { unterlage: [] };
            }

            // Füge die neue Bewilligung zur entsprechenden Position hinzu
            updatedUnterlageData[posIndex].unterlage = [
                ...updatedUnterlageData[posIndex].unterlage,
                newUntrlageData
            ];
            return updatedUnterlageData;
        });
    };


    const updateSpecificPosition = (warePositionsnummer, updatedData) => {
        setAtlasPositionData((prevPositions) => 
            prevPositions.map((position) =>
                position.warePositionsnummer === warePositionsnummer
                    ? { ...position, ...updatedData }  // Position aktualisieren
                    : position                         // Andere Positionen unverändert lassen
            )
        );
    };

    const [activePosition, setActivePosition] = useState({});
    


    // Funktion um die letzte Position zu löschen
    const deleteLastPosition = () => {
        setAtlasPositionData((prevPositions) => {
            // Überprüfen, ob es Positionen zum Löschen gibt
            if (prevPositions.length === 0) {
                console.log("Keine Positionen zum Löschen vorhanden.");
                return prevPositions; // Keine Änderungen, wenn keine Positionen vorhanden sind
            }
    
            const indexToDelete = prevPositions.length - 1; // Index der letzten Position
            const lastPosition = prevPositions[indexToDelete]; // Letzte Position
    
            // Überprüfen, ob die aktive Position gelöscht werden soll
            if (activePosition && activePosition.warePositionsnummer === lastPosition.warePositionsnummer) {
                toast.error("Die aktive Position kann nicht gelöscht werden.", {
                    position: "bottom-right",
                });
                return prevPositions; // Keine Änderungen vornehmen, wenn die aktive Position gelöscht werden soll
            }
    
            // Entferne die letzte Position, da die aktive Position nicht gelöscht werden soll
            const newPositions = prevPositions.slice(0, prevPositions.length - 1); 
            return newPositions;
        });
    };

    
    return (
        <PositionDataContext.Provider value={{
            atlasPositionData,
            updateSpecificPosition,
            setActivePosition,
            activePosition,
            deleteLastPosition,

            //Data for bewilligung
            PosBewilligung,
            addBewilligunPos,
            setPosBewilligung,

            //Verpackung
            addVerpackungPos,
            PosVerpackung,
            setPosVerpackung,

            //Unterlage
            addUnterlagePos,
            PosUnterlage
            }}>

            {loading ? <PageLoader/> : (
              <>
                  {children}
              </>
            )}
        </PositionDataContext.Provider>
    );
};

export const usePositionData = () => useContext(PositionDataContext);