import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { Container } from "../../../../Components/Layout/Container/Container"
import { 
    TableContainer,
    TableHeader,
    TableHeaderItem,
    TableBody,
    TableRow,
    TableItem
 } from "../../../../Components/DataDisplay/Table/Table"

import { Input } from "../../../../Components/Input/TextField/Input"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"
import { usePositionData } from "../../../../Utils/Contexts/PositionDataContext"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { useEffect, useState } from "react"

export const PosBewilligung = () => {

    //Get Data
    const {addBewilligunPos, PosBewilligung, activePosition} = usePositionData();
    const {atlasData} = useFormData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);


    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const[newPosBewilligung, setNewPosBewilligung] = useState({
        art: '',
        referenznummer: '',
        bewilligungsinhaber: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPosBewilligung((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewPosBewilligung((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddBewilligung = (e) =>{
        e.preventDefault();
        addBewilligunPos(activePosition.warePositionsnummer, newPosBewilligung);

        console.log(activePosition.warePositionsnummer, newPosBewilligung);
        setNewPosBewilligung({ 
            art: '', 
            referenznummer: '',
            bewilligungsinhaber: ''
        });
    }


    return(
        <>
        <FormWrapper>
            <ColumnGrid>
                <FormBox header="Bewilligung" type="center">
                    <Container size="small">
                        <DropDownButton
                            name='Art'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '143')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('art', item)}
                            dropname='art'
                        />
                        <Input
                            label="Referenznummer"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="referenznummer"
                            value={PosBewilligung.referenznummer}
                        />
                        <Input
                            label="Beweilligungsinhaber"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="bewilligungsinhaber"
                            value={PosBewilligung.bewilligungsinhaber}
                        />
                        <ButtonGroup>
                            <Button onClick={handleAddBewilligung}>Hinzufügen</Button>
                        </ButtonGroup>
                    </Container>
                </FormBox>

                <TableContainer>
                        <TableHeader>
                            <TableHeaderItem>
                                Nr.
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Art
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Referenznummer
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Bewilligungsinhaber
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Optionen
                            </TableHeaderItem>
                        </TableHeader>
                        {/* Table Body */}
                        {activePosition?.bewilligung.map((item, index) => (
                            <>
                                <TableRow>
                                    <TableItem>
                                        {index + 1}
                                    </TableItem>
                                    <TableItem>
                                        {item.art}
                                    </TableItem>
                                    <TableItem>
                                        {item.referenznummer}
                                    </TableItem>
                                    <TableItem>
                                        {item.bewilligungsinhaber}
                                    </TableItem>
                                    <TableItem>
                                        Löschen
                                    </TableItem>
                                </TableRow>
                            </>))}
                        <TableBody>
                        </TableBody>
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}