import React, { useEffect, useState } from 'react';
import { BaseWrapper, ToolBar, ListContentBase} from '../../Components/Layout/BaseLayouts/Base/Base';
import { ButtonGroup } from '../../Components/Button/Buttons';
import axios from 'axios';
import Base from '../../Api/Base.json';
import { useFormData } from '../../Utils/Contexts/FormDataContext';
import { SearchBar } from '../../Components/FilterOptions/SearchBar/SearchBar';

//Table
import { 
    TableContainer, 
    TableHeader, 
    TableHeaderItem, 
    TableBody, 
    TableItem, 
    TableRow,
    CardContainer,
    CardItem
} from '../../Components/DataDisplay/Table/Table';

import { PageLoader } from '../../Components/LoadingAnimation/PageLoader/PageLoader';

//window size
import useWindowSize from '../../Utils/Evenlistner/GetWindowSize';
import { useNavigate } from 'react-router-dom';

function ReyBexDataListIems() {
    //Handle responsive
    const {width} = useWindowSize();


    //Set the position open: 
    const { setLsNumber } = useFormData();

    const [reyBexLs, setReyBexLs] = useState([]);
    const [loading, setLoading] = useState(null);
    const {filteresLs} = useFormData();

    //Get ReyBex LS
    useEffect(() => {
        const r_token = localStorage.getItem('r_token');
        setLoading(true)
        const GetReyBexLs = async () => {
            if(r_token){
                try{
                    const reyls = await axios.get(`${Base.reybex}domains/salesHead?docType.name=eq(Lieferschein)`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${r_token}`,
                        }
                    })

                    if(reyls.status === 200){
                        const ReyBexApiData = await reyls.data;

                        if(ReyBexApiData){
                            setReyBexLs(ReyBexApiData);
                        }

                    }


                } catch(err){
                    console.log(err)
                }
            }
            setLoading(false)
        };

        GetReyBexLs();
    },[])

    console.log(reyBexLs)

    //Select LS

    const navigate = useNavigate();

    const selectReyBexLs = (ls_number, ls_id) => {
        setLsNumber((prevData) => ({
            ...prevData,
            ls_id: ls_id,
            ls_number: ls_number
        }));
        navigate(`/app/create/atlasmessage?step=headerdata`);
    };

    return ( 
        <>
        {loading ? (
            <>
            <PageLoader/>
            </>
        ) : (
            <>
            <BaseWrapper>
                <ToolBar>
                    <ButtonGroup type="end">
                        <SearchBar items={reyBexLs}/>
                    </ButtonGroup>
                </ToolBar>
                <ListContentBase>
                    {width > 1150 ? (
                    <>
                    <TableContainer>
                        <TableHeader>
                            <TableHeaderItem>Nr.</TableHeaderItem>
                            <TableHeaderItem>Lieferschein Nr.</TableHeaderItem>
                            <TableHeaderItem>Erstellt am.</TableHeaderItem>
                            <TableHeaderItem>Kunde</TableHeaderItem>
                            <TableHeaderItem>Bestimmungsland</TableHeaderItem>
                            <TableHeaderItem>Positionen</TableHeaderItem>
                            <TableHeaderItem>Rechnungsbetrag</TableHeaderItem>
                        </TableHeader>
                        <TableBody>
                            {filteresLs.length < 0 ? (
                                <>
                                {reyBexLs?.map((item, index) => (
                                    <TableRow key={index} onDoubleClick={() => selectReyBexLs(item.docNumber, item.id)}> 
                                        <TableItem>{index + 1}</TableItem>
                                        <TableItem>{item.docNumber}</TableItem>
                                        <TableItem>{item.docDate}</TableItem>
                                        <TableItem>{item.customer.name}</TableItem>
                                        <TableItem>{`${item.customerCountry.code} - ${item.customerCountry.name}`}</TableItem>
                                        <TableItem>{item.items.length}</TableItem>
                                        <TableItem>{item.grossAmount.toFixed(2) + "€"}</TableItem>
                                    </TableRow>
                                ))}
                                </>
                            ) : (
                                <>
                                {filteresLs?.map((item, index) => (
                                    <TableRow key={index} onDoubleClick={() => selectReyBexLs(item.docNumber, item.id)}> 
                                        <TableItem>{index + 1}</TableItem>
                                        <TableItem>{item.docNumber}</TableItem>
                                        <TableItem>{item.docDate}</TableItem>
                                        <TableItem>{item.customer.name}</TableItem>
                                        <TableItem>{`${item.customerCountry.code} - ${item.customerCountry.name}`}</TableItem>
                                        <TableItem>{item.items.length}</TableItem>
                                        <TableItem>{item.grossAmount.toFixed(2) + "€"}</TableItem>
                                    </TableRow>
                                ))}
                                </>
                            )}
                        </TableBody>
                    </TableContainer>
                    </>
                    ) : (
                    <>
                    <CardContainer>
                        {reyBexLs?.map((item, index) => (
                            <CardItem
                            No={index + 1}
                            lsNumber={item.docNumber}
                            CompanyName={item.customer.name}
                            onDoubleClick={() => selectReyBexLs(item.docNumber, item.id)}
                            key={index}
                            />
                        ))}
                    </CardContainer>
                    </>
                    )}
                </ListContentBase>
            </BaseWrapper>
            
            </>
        )}
        </>
     );
}

export default ReyBexDataListIems;