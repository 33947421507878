import { useEffect, useState } from 'react';
import './Buttons.css';


//Import Loader
import loading from './loading.gif';

export const Button = ({children, onClick, type = "button", disabled = false, isLoading = false}) => {
    return(
        <>
        <button 
        className="bap_button" 
        onClick={onClick} 
        type={type} 
        disabled={disabled}
        >
            {isLoading === true ? (
                <>
                    <img className='bap_loading_buttom_icon' src={loading} alt="" />
                </>
            ) : null}
        {children}
        </button>
        </>
    )
};



export const ButtonGroup = ({children, type}) => {

    const [Positionend, setEnd] = useState(null);

    useEffect(() => {
        const end = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0px 10px',
            boxSizing: 'border-box',
            }


        if(type === 'end'){
            setEnd(end)
        }
    }, [type])

    return(
        <>
        <div className="bap_button_button_group" style={Positionend}>
            {children}
        </div>
        </>
    )
};