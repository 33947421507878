import axios from 'axios';
import React, {createContext, useState, useContext, useEffect } from 'react';
import Base from '../../Api/Base.json';

import { PageLoader } from '../../Components/LoadingAnimation/PageLoader/PageLoader';

const FormDataContext = createContext();

export const FormDataProvider = ({children}) => {


    //Data which we want to get
    const [reybexData, setReybexData] = useState();
    const [atlasData, setAtlasData] = useState();
    const [atlasAddressData, setAtlasAddressData] = useState();


    const[ls_number, setLsNumber] = useState({
        ls_number: "",
        ls_id: ""
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Get the Reybex data
      const GetReyBexHeaderData = async () => {
          setLoading(true);
          const r_token = localStorage.getItem('r_token');
          try {
              const reybex = await axios.get(`${Base.reybex}domains/salesHead?docNumber=${ls_number.ls_number}`, {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Basic ${r_token}`,
                  },
              });
  
              if (reybex.status === 200) {
                  const reybexloadData = await reybex.data[0];
                  if (reybexloadData) {
                      setReybexData(reybexloadData);
                      console.log(reybexloadData)
                      // Update headerData mit den Werten aus reybexloadData
                      setHeaderData((prevHeaderData) => ({
                          ...prevHeaderData,
                          lrn: reybexloadData.docNumber || "",
                          referenznummerUCR: reybexloadData.docNumber || "",
                          gesamtRohmasse: reybexloadData.grossWeight || "",
                          inRechnunggestellterGesamtbetrag: reybexloadData.totalAmount || "",
                          bestimmungsLand: reybexloadData.customerCountry.code || "",
                          rechnungswahrung: reybexloadData.currency.code || "",
                          empfanger: {
                            tin: "",
                            niederlassungsNummer: "",
                            name: reybexloadData.customer.name || "",
                            strasse: reybexloadData.customerStreet1 || "",
                            plz: reybexloadData.customerZipStreet || "",
                            ort: reybexloadData.customerCity || "",
                            land: reybexloadData.customerCountry.code || "",
                          }
                      }));
                  }
              }
          } catch (error) {
              console.error(error);
          }
      };
  
      GetReyBexHeaderData();
  }, [ls_number]);

  //Getting Atlasdata    
  useEffect(() => {
      const GetAtlasData = async () => {
          if (reybexData) {
              const b_accesstoken = localStorage.getItem('b_accesstoken');
              const b_token = localStorage.getItem('b_token');
  
              try {
                  const AtlasCodeRequest = {
                      query: "query($type: [String]) { getOptionsForCompany(type: $type) { type userid options{ code version description bemerkung description2 description3 description4 description5 isSelected }} }",
                      variables: {
                          type: ["104", "105", "106", "108", "110", "111", "113", "114", "115", "116", "118", "122", "123", "124", "125", "128", "129", "133", "137", "151", "152",
                                "153", "139", "410", "146", "155", "156", "157", "158", "142", "141", "136", "143", "120", "114", "140", "160","121"], 
                      },
                  };


                  const AtlasAddressRequest = {
                        query: "query($type: [String]) { getExportsAddress(type: $type) { type userId addresses{ tin niederlassungsNummer name ort land ansprechName referenceNum strasse phone ansprechEmail kundenNum plz }} }",
                        variables: {
                            type: [
                            "01",
                            "02",
                            "04",
                            "05"
                            ]
                        }
                  }
  
                  const atlas = await axios.post(
                      `${Base.beo}GetData/common/codelist`,
                      JSON.stringify(AtlasCodeRequest),
                      {
                          headers: {
                              'Content-Type': 'application/json',
                              'bearerToken': b_accesstoken,
                              'token': b_token,
                          },
                      }
                  );
  
                  if (atlas.status === 200) {
                      const atlasData = await atlas.data;
                      if (atlasData) {
                          setAtlasData(atlasData.getOptionsForCompany);
                      }
                  }


                  //Get Address Data:
                  const atlasAddressData = await axios.post(
                    `${Base.beo}GetData/ausfuhr/address`,
                        JSON.stringify(AtlasAddressRequest),
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'bearerToken': b_accesstoken,
                                'token': b_token,
                            },
                        }
                    );

                    if (atlasAddressData.status === 200) {
                        const atlasAddress = await atlasAddressData.data;
                        if (atlasAddress) {
                            setAtlasAddressData(atlasAddress.getExportsAddress);
                        }
                    }


              } catch (error) {
                  console.error(error);
              }
          }
          setLoading(false);
      };
      GetAtlasData();
  }, [reybexData]);



    //mapping the Data along with the reybex and atlas data.


    const [headerData, setHeaderData] = useState({
        eoriNiederlassungsnummer: "",
        dienststellennummer: "",
        lrn: "",
        artderAnmeldung: "",
        artderAusfuhranmeldung: "",
        beteiligtenKonstellation: "",
        beforderungskostenZahlungsart: "", 
        zeitpunktderAnmeldung: "",
        massgeblichesDatum: "",
        kopfDatumdesAusgangs: "",
        zeitpunktDerGestellung: "",
        zeitpunktdesEndesderLadetatigkeit: "",
        sicherheit: "",
        besondereUmstande: "",
        inRechnunggestellterGesamtbetrag: "",
        rechnungswahrung: "",
        gestellungszollstelle: "",
        ausfuhrzollstelleDienststellennummer: "",
        ausfuhrZollstelleFurDieErganzende: "",
        vorgeseheneAusgangszollstelleDienststellennummer: "",
        tatsachlicheAusgangszollstelleDienststellennummer: "",
        artdesGeschafts: "",
        ausfuhrland: "",
        bestimmungsLand: "",
        lieferbedingungIncotermCode: "",
        lieferbedingungUnLocode: "",
        lieferbedingungOrt: "",
        lieferbedingungLand: "",
        lieferbedingungText: "",
        containerIndikator: "",
        inlandischerVerkehrszweig: "",
        verkehrszweigAnDerGrenze: "",
        gesamtRohmasse: "",
        referenznummerUCR: "",
        anmelder: {
            tin: "",
            niederlassungsNummer: "",
            name: "",
            strasse: "",
            plz: "",
            ort: "",
            land: "",
            phone: "",
            ansprechName: "",
            ansprechEmail: ""
        },
        empfanger: {
            tin: "",
            niederlassungsNummer: "",
            name: "",
            strasse: "",
            plz: "",
            ort: "",
            land: ""
        },
        vertreter: {
            tin: "",
            niederlassungsNummer: "",
            phone: "",
            ansprechName: "",
            ansprechEmail: ""
        },
        subunternehmer: {
            tin: "",
            niederlassungsNummer: "",
            name: "",
            strasse: "",
            plz: "",
            ort: "",
            land: ""
        },
        registriernummerextern: "",
        warenortArtdesOrtes: "",
        warenortArtDerOrtsbestimmung: "",
        warenortBewilligungsnummer: "",
        warenortZusatzlicheKennung: "",
        warenortUnLocode: "",
        warenortGnssBreite: "",
        warenortGnssLang: "",
        beforderungsmittelderGrenzeArt: "",
        beforderungsmittelderGrenzeKennzeichen: "",
        beforderungsmittelderGrenzeStaatszugehorigkeit: "",
      });
    
    const [bewilligung, setBewilligung] = useState({
            bewilligung: []
        }
    )


    const [suplyActors, setSuplyActors] = useState({
        lieferkettenBeteiligter: []
    })

    const [unterlageHeader, setUnterlage] = useState({
        unterlage:[]
    });


    const [sonstigerVerweis, setSonstigerVerweis] = useState({
        sonstigerVerweis:[]
    });


    const [zusätzlicheInformationen, setZusätzlicheInformationen] = useState({
        zusatzlicherInformation:[]
    });


    const [befoerderungsMittelAnDerGrenze, setBefoerderungsMittelAnDerGrenze] = useState({
        beforderungsmittelBeimAbgang: []
    });


    // Funktionen zum Aktualisieren der Daten
    const updateHeaderData = (newHeaderData) => {
        setHeaderData((prevHeaderData) => ({
        ...prevHeaderData,
        ...newHeaderData,
        }));
    };

    const addBewilligungEntry = (newBewilligungData) => {
        setBewilligung((prevBewilligungData) => ({
            bewilligung: [...prevBewilligungData.bewilligung, newBewilligungData]
        }));
    };

    const addSuplyActorEntry = (newSuplyActorData) => {
        setSuplyActors((prevSuplyActorData) => ({
            lieferkettenBeteiligter: [...prevSuplyActorData.lieferkettenBeteiligter, newSuplyActorData]
        }));
    };

    const addUnterlageHeader = (newUnterlageData) =>{
        setUnterlage((prevUnterlageData) => ({
            unterlage: [...prevUnterlageData.unterlage, newUnterlageData]
        }));
    }


    const addSonstigerVerweis = (newSonstigerVerweis) =>{
        setSonstigerVerweis((prevSonstigerVerweis) => ({
            sonstigerVerweis: [...prevSonstigerVerweis.sonstigerVerweis, newSonstigerVerweis]
        }));
    }


    const addZusätzlicheInformationen = (newZusätzlicheInformation) =>{
        setZusätzlicheInformationen((prevZusätzlicheInformation) => ({
            zusatzlicherInformation: [...prevZusätzlicheInformation.zusatzlicherInformation, newZusätzlicheInformation]
        }));
    };


    const addBefoerderungsMittelAnDerGrenze = (newBefoerderungsMittelAnDerGrenze) =>{
        setBefoerderungsMittelAnDerGrenze((prevBefoerderungsMittelAnDerGrenze) => ({
            beforderungsmittelBeimAbgang: [...prevBefoerderungsMittelAnDerGrenze.beforderungsmittelBeimAbgang, newBefoerderungsMittelAnDerGrenze]
        }));
    };


    //ReyBex View: 
    const [filteresLs, setFilteredLs] = useState([]);
    

    return (
        <FormDataContext.Provider value={{
            atlasData, 
            atlasAddressData,
            headerData,
            setHeaderData, 
            updateHeaderData,
            addBewilligungEntry, 
            bewilligung,
            addSuplyActorEntry,
            suplyActors,
            unterlageHeader, 
            addUnterlageHeader,
            addSonstigerVerweis,
            sonstigerVerweis,
            zusätzlicheInformationen,
            addZusätzlicheInformationen,
            befoerderungsMittelAnDerGrenze,
            addBefoerderungsMittelAnDerGrenze,
            setLsNumber,
            ls_number,
            setFilteredLs,
            filteresLs
            }}>

            {loading ? <PageLoader/> : (
              <>
                  {children}
              </>
            )}
        </FormDataContext.Provider>
    );
};

export const useFormData = () => useContext(FormDataContext);