import React, { useEffect } from 'react';
import './CreateAtlasMessage.css';

import { BaseWrapper, CreateAtlasWrapper, ToolBar} from '../../Components/Layout/BaseLayouts/Base/Base';
import { TabHandler, TabItem} from '../../Components/Navigation/TabHandler/TabHandler';
import { ContentBase } from '../../Components/Layout/BaseLayouts/BaseContent/ContentBase';


//importing the sections
import HeaderData from './ATLAS-MESSAGE-TABS/Kopfdaten/Header';
import { PermissionData } from './ATLAS-MESSAGE-TABS/Bewilligung/Permission';
import { SuplyChaingActors } from './ATLAS-MESSAGE-TABS/LieferkettenBeteiligter/LieferKettenBeteiligter';
import { UnterlageInformationHeader } from './ATLAS-MESSAGE-TABS/Unterlage/Unterlage';
import { SonstigerVerweisHeader } from './ATLAS-MESSAGE-TABS/SonstigerVerweis/SonstigerVerweis';
import { ZusätzlicheInformationenHeader } from './ATLAS-MESSAGE-TABS/ZusätzlicheInformation/ZusätzlicheInformation';
import { BeförderungeMittelAmAbgang } from './ATLAS-MESSAGE-TABS/BeförderungsmittelAmAbgang/BeförderungsmittelAmAbgang';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup } from '../../Components/Button/Buttons';

import useWindowSize from '../../Utils/Evenlistner/GetWindowSize';
import { TopSectionLabels, TopSectionLabel } from '../../Components/Layout/TopSectionLabels/TopSectionLabels';


function CreateAtlasMessage() {


    //Handle the window size
    const {width} = useWindowSize();


    useEffect(() => {
        const handleBeforeUnlaod = (e) =>{
            const message = "Möchten Sie die Seite wirklich verlassen?";
            e.returnValue = message;
            return message;
        }

        window.addEventListener('beforeunload', handleBeforeUnlaod);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnlaod);
        }
    })


    const [searchParams] = useSearchParams();
    const step = searchParams.get('step');

    let content = null;

    switch(step) {
        case 'headerdata':
            content = <HeaderData />;
            break; 
        case 'permissiondata':
            content = <PermissionData />;
            break; 
        case 'suplychainactors':
            content = <SuplyChaingActors />;
            break; 
        case 'unterlage':
            content = <UnterlageInformationHeader />;
            break; 
        case 'sonstiger-verweis':
            content = <SonstigerVerweisHeader />;
            break; 
        case 'zusaetzliche-informationen':
            content = <ZusätzlicheInformationenHeader />;
            break; 
        case 'befoerderung-am-abgang':
            content = <BeförderungeMittelAmAbgang />;
            break; 
        default: 
            content = <HeaderData />;
            break;
    }


    //Handle Query change
    const navigate = useNavigate();

    const goToStep = (step) => {
        navigate(`?step=${step}`);
    }


    //cancle the atlas request
    const CancleMessageCreation = () => {
        navigate("/app/reybex/listitems");
    }


    return ( 
        <>
        <BaseWrapper>
            <ToolBar>
                {/*Here the Toolbar buttons will be displayed*/}
                <ButtonGroup>
                    <Button onClick={CancleMessageCreation}>Abbrechen</Button>
                    <Button onClick={() => navigate("/app/create/atlasmessage/position?step=articleposition")} >Positionen</Button>
                </ButtonGroup>
            </ToolBar>

            <CreateAtlasWrapper>
                <TabHandler>
                    <TabItem goTo={() => goToStep("headerdata")} active={step === "headerdata"}>Kopfdaten</TabItem>
                    <TabItem goTo={() => goToStep("permissiondata")} active={step === "permissiondata"}>Bewilligung</TabItem>
                    <TabItem goTo={() => goToStep("suplychainactors")} active={step === "suplychainactors"}>Lieferketten-Beteiligter</TabItem>
                    {/*
                    <TabItem>Beförderungsroute der Sendung</TabItem>
                    <TabItem>Wiedereinfuhr</TabItem>
                    <TabItem>Nämlichkeitsmittel</TabItem>
                    <TabItem>Erzeugnis</TabItem>
                    <TabItem>Vorpapier</TabItem>
                    <TabItem>Beförderungsroute der Sendung</TabItem>
                    <TabItem>Transportausrüstung</TabItem>
                    <TabItem>Transportdokument</TabItem>
                    */}
                    <TabItem goTo={() => goToStep("unterlage")} active={step === "unterlage"}>Unterlage</TabItem>
                    <TabItem goTo={() => goToStep("sonstiger-verweis")} active={step === "sonstiger-verweis"}>Sonstiger Verweis</TabItem>
                    <TabItem goTo={() => goToStep("zusaetzliche-informationen")} active={step === "zusaetzliche-informationen"}>Zusätzliche Informationen</TabItem>
                    <TabItem goTo={() => goToStep("befoerderung-am-abgang")} active={step === "befoerderung-am-abgang"}>Beförderungsmittel am Abgang</TabItem>
                </TabHandler>

                {/*Here the Main content wil getting*/}
                <ContentBase>
                    {/*Here the Tab Handler will be displayed*/}
                    {width < 1250 ? (
                        <>
                        <TopSectionLabels>
                            <TopSectionLabel goTo={() => goToStep("headerdata")} active={step === "headerdata"}>Kopfdaten</TopSectionLabel>
                            <TopSectionLabel goTo={() => goToStep("permissiondata")} active={step === "permissiondata"}>Bewilligung</TopSectionLabel>
                            <TopSectionLabel goTo={() => goToStep("suplychainactors")} active={step === "suplychainactors"}>Lieferketten-Beteiligter</TopSectionLabel>
                            <TopSectionLabel goTo={() => goToStep("unterlage")} active={step === "unterlage"}>Unterlage</TopSectionLabel>
                            <TopSectionLabel goTo={() => goToStep("sonstiger-verweis")} active={step === "sonstiger-verweis"}>Sonstiger Verweis</TopSectionLabel>
                            <TopSectionLabel goTo={() => goToStep("zusaetzliche-informationen")} active={step === "zusaetzliche-informationen"}>Zusätzliche Informationen</TopSectionLabel>
                            <TopSectionLabel goTo={() => goToStep("befoerderung-am-abgang")} active={step === "befoerderung-am-abgang"}>Beförderungsmittel am Abgang</TopSectionLabel>
                        </TopSectionLabels>
                        </>
                    ) : null}


                    {content}
                </ContentBase>
            </CreateAtlasWrapper>
        </BaseWrapper>
        </>
     );
}

export default CreateAtlasMessage;