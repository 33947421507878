import axios from "axios";
import Base from '../Base.json';
export const CreateAtlasMessage = async (
    atlasPositionData, 
    headerData, 
    bewilligung, 
    suplyActors, 
    befoerderungsMittelAnDerGrenze, 
    zusätzlicheInformationen, 
    unterlageHeader
) => {


    console.log("Atlas Position:", atlasPositionData)
    console.log(headerData) 


    const SendAtlasData = {

            dataIdentifier: "EXPDAT",
            kundenNumber: "",
            mandantId: "FTAM",
            module: {
                ausfuhr: {
                    data: true,
                    email: "",
                    autosend: false
                }
            },
            messageData: {
                ausfuhr: {
                    expdat: [
                            {
                                kopf: {
                                    eoriNiederlassungsnummer: headerData.eoriNiederlassungsnummer || "",
                                    dienststellennummer: headerData.dienststellennummer || "",
                                    lrn: headerData.lrn || "",
                                    artderAnmeldung: headerData.artderAnmeldung || "",
                                    artderAusfuhranmeldung: headerData.artderAusfuhranmeldung || "",
                                    beteiligtenKonstellation: headerData.beteiligtenKonstellation || "",
                                    zeitpunktderAnmeldung: headerData.zeitpunktderAnmeldung || "",
                                    massgeblichesDatum: headerData.massgeblichesDatum || "",
                                    kopfDatumdesAusgangs: headerData.kopfDatumdesAusgangs || "",
                                    zeitpunktDerGestellung: headerData.zeitpunktDerGestellung || "",
                                    zeitpunktdesEndesderLadetatigkeit: headerData.zeitpunktdesEndesderLadetatigkeit || "",
                                    sicherheit: headerData.sicherheit || "",
                                    besondereUmstande: headerData.besondereUmstande || "",
                                    inRechnunggestellterGesamtbetrag: headerData.inRechnunggestellterGesamtbetrag || "",
                                    rechnungswahrung: headerData.rechnungswahrung || "",
                                    bewilligung: bewilligung?.bewilligung.map((item, index) => (
                                        {
                                            art: item.art || "", 
                                            referenznummer: item.referenznummer || "",
                                        }
                                    )),
                                    gestellungszollstelle: headerData.gestellungszollstelle || "",
                                    ausfuhrzollstelleDienststellennummer: "DE002152",
                                    ausfuhrZollstelleFurDieErganzende: "DE002181",
                                    vorgeseheneAusgangszollstelleDienststellennummer: headerData.vorgeseheneAusgangszollstelleDienststellennummer || "",
                                    tatsachlicheAusgangszollstelleDienststellennummer: headerData.tatsachlicheAusgangszollstelleDienststellennummer,
                                    "aussenwirtschaftsrechtlicherAusfuhrer": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    "ausfuhrer": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    "anmelder": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": "",
                                        "phone": "",
                                        "ansprechName": "",
                                        "ansprechEmail": ""
                                    },
                                    "vertreter": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "phone": "",
                                        "ansprechName": "",
                                        "ansprechEmail": ""
                                    },
                                    "subunternehmer": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    artdesGeschafts: headerData.artdesGeschafts || "",
                                    ausfuhrland: headerData.ausfuhrland || "",
                                    bestimmungsLand: headerData.bestimmungsLand || "",
                                    "lieferkettenBeteiligter": [
                                        {
                                            "funktion": "",
                                            "identifikationsnummer": ""
                                        }
                                    ],
                                    lieferbedingungIncotermCode: headerData.lieferbedingungIncotermCode || "",
                                    lieferbedingungUnLocode: headerData.lieferbedingungUnLocode || "",
                                    lieferbedingungOrt: headerData.lieferbedingungOrt || "",
                                    lieferbedingungLand: headerData.lieferbedingungLand || "",  
                                    lieferbedingungText: headerData.lieferbedingungText || "",
                                    "wiedereinfuhr": [
                                        {
                                            "wiedereinfuhrLand": ""
                                        }
                                    ],
                                    "namlichkeitsmittel": [
                                        {
                                            "namlichkeitsmitteArt": "",
                                            "namlichkeitsmittelTextlicheBeschreibung": ""
                                        }
                                    ],
                                    "erzeugnis": [
                                        {
                                            "erzeugnisWarenbezeichnung": "",
                                            "unterpositionDesHarmonisiertenSystems": "",
                                            "unterpositionDerKombiniertenNomenklatur": ""
                                        }
                                    ],
                                    "vorpapier": [
                                        {
                                            "art": "",
                                            "qualifikator": "",
                                            "referenznummer": ""
                                        }
                                    ],
                                    "unterlage": [
                                        {
                                            "art": "",
                                            "qualifikator": "",
                                            "referenznummer": "",
                                            "zeilenPositionsnummer": "",
                                            "name": "1",
                                            "datumDerAusstellung": "",
                                            "gultigkeitsdatum": ""
                                        }
                                    ],
                                    "sonstigerVerweis": [
                                        {
                                            "art": "",
                                            "qualifikator": "",
                                            "referenznummer": ""
                                        }
                                    ],
                                    "zusatzlicherInformation": [
                                        {
                                            "code": "",
                                            "text": ""
                                        }
                                    ],
                                    containerIndikator: headerData.containerIndikator || "",
                                    inlandischerVerkehrszweig: headerData.inlandischerVerkehrszweig || "",
                                    verkehrszweigAnDerGrenze: headerData.verkehrszweigAnDerGrenze || "",
                                    gesamtRohmasse: headerData.gesamtRohmasse || "",
                                    referenznummerUCR: headerData.referenznummerUCR || "",
                                    registriernummerextern: headerData.registriernummerextern || "",
                                    "beforderer": {
                                        "tin": "",
                                        "niederlassungsNummer": ""
                                    },
                                    "versender": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    "empfanger": {
                                        "tin": headerData.empfanger.tin || "",
                                        "niederlassungsNummer": headerData.empfanger.niederlassungsNummer || "",
                                        "name": headerData.empfanger.name || "",
                                        "strasse": headerData.empfanger.strasse || "",
                                        "plz": headerData.empfanger.plz || "",
                                        "ort": headerData.empfanger.ort || "",
                                        "land": headerData.empfanger.land || ""
                                    },
                                    "transportausrustung": [
                                        {
                                            "containernummer": "",
                                            "anzahlderVerschlusse": "",
                                            "verschluss": [
                                                {
                                                    "verschlusskennzeichen": ""
                                                }
                                            ],
                                            "warenpositionsverweis": [
                                                {
                                                    "positionsnummer": ""
                                                }
                                            ]
                                        }
                                    ],
                                    warenortArtdesOrtes: headerData.warenortArtdesOrtes || "",
                                    warenortArtDerOrtsbestimmung: headerData.warenortArtDerOrtsbestimmung || "",
                                    warenortBewilligungsnummer: headerData.warenortBewilligungsnummer || "",
                                    warenortZusatzlicheKennung: headerData.warenortZusatzlicheKennung || "",
                                    warenortUnLocode: headerData.warenortUnLocode || "",
                                    warenortGnssBreite: headerData.warenortGnssBreite || "",
                                    warenortGnssLang: headerData.warenortGnssLang || "",
                                    "warenort": {
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": "",
                                        "phone": "",
                                        "ansprechName": "",
                                        "ansprechEmail": ""
                                    },
                                    beforderungsmittelBeimAbgang: befoerderungsMittelAnDerGrenze?.beforderungsmittelBeimAbgang.map((item, index) => (
                                        {
                                            artderIdentifikation: item.artderIdentifikation || "", 
                                            staatszugehorigkeit: item.staatszugehorigkeit || "",
                                            kennzeichen: item.kennzeichen || "",
                                        }
                                    )),
                                    "beforderungsroute": {},
                                    beforderungsmittelderGrenzeArt: "40",
                                    beforderungsmittelderGrenzeKennzeichen: "111",
                                    beforderungsmittelderGrenzeStaatszugehorigkeit: "AF",
                                    "transportdokument": [
                                        {
                                            "art": "",
                                            "qualifikator": "",
                                            "referenznummer": ""
                                        }
                                    ],
                                    beforderungskostenZahlungsart: headerData.beforderungskostenZahlungsart || "",
                                },
                                //Hier haben wir die Positionen
                                position: atlasPositionData
                            }
                        ]
                    }
                }
    }


        //Sending Request:
        if(SendAtlasData) {
            console.log("Data has been sent", SendAtlasData);
            const b_token = localStorage.getItem('b_token');
            const b_accesstoken = localStorage.getItem('b_accesstoken');
            try{
                const atlas = await axios.post(
                    `${Base.beo}PutData`,
                    JSON.stringify(SendAtlasData),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'bearerToken': b_accesstoken,
                            'token': b_token,
                        },
                    }
                );

                if(atlas.status === 200){
                    console.log("Atlas Response", atlas.data);
                }
            }catch(err){
                console.log(err)
            }
        }

}