import './Table.css';

export const TableContainer = ({children}) => {
    return(
        <>
        <div className="bap_table_container">
            {children}
        </div>
        </>
    )
}


export const TableHeader = ({children}) => {
    return(
        <>
        <div className="bap_tabler_header">
            {children}
        </div>
        </>
    )
};


export const TableHeaderItem = ({children}) => {
    return(
        <>
        <div className="bap_table_header_item">
            <span>
                {children}
            </span>
        </div>
        </>
    )
};


export const TableBody = ({children}) => {
    return(
        <>
        <div className="bap_table_body">
            {children}
        </div>
        </>
    )
};

export const TableRow = ({children, onDoubleClick}) => {

    const handleNextStep = () => {
        onDoubleClick()
    }

    return(
        <>
        <div className="bap_table_body_row" onDoubleClick={handleNextStep}>
            {children}
        </div>
        </>
    )
}

export const TableItem = ({children}) => {
    return(
        <>
        <div className="bap_table_body_item">
            <span>
                {children}
            </span>
        </div>
        </>
    )
};





//This is the card for responsive view: 

export const CardContainer = ({children}) => {
    return(
        <>
        <div className="bap_card_container_wrapper">
            {children}
        </div>
        </>
    )
}


export const CardItem = ({lsNumber, CompanyName, No, onDoubleClick}) => {
    
    const handleOpenLs = () =>{
        onDoubleClick()
    }
    
    return(
        <>
        <div className="bap_card_item_wrapper" onDoubleClick={handleOpenLs}>
            <div className="bap_card_content_wrapper">
                <div className="bap_card_square_item">
                    <span>{No}</span>
                </div>
                <div className="bap_card_text_information_box">
                    <div className="bap_text_item_wrapper">
                        <span>{lsNumber}</span>
                        <p>{CompanyName}</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};